<template>
  <div class="">
    <main class="md:ml-12" v-if="stores">
      <div class="flex-col mt-12 mb-8">
        <h2>Sales</h2>
        <h3 class="font-bold mt-4 text-2xl uppercase">Pos</h3>
      </div>
      <div class=" bg-white h-56 rounded-lg big-shadow">
        <div
          class="mt-24 h-full mb-24 mx-12  flex-col flex md:flex-row items-center justify-center md:justify-between"
        >
          <div class="flex-column w-full md:w-6/12">
            <!-- <trac-dropdown
              :menuItems="stores"
              title="Select Store"
              class="w-full"
              @input="setStore($event)"
            ></trac-dropdown> -->
            <!-- @optionSelected="selectOption($event)" -->
            <trac-dropdown-exteneded
              :neededProperty="'name'"
              placement="left"
              :options="stores"
              @optionSelected="setStore($event)"
              class="mb-2 md:mb-0"
            >
            </trac-dropdown-exteneded>
          </div>
          <div class="flex-column w-full md:w-1/4">
            <trac-button
              :disabled="!store"
              @button-clicked="gotoPOSDashboard"
              class="uppercase w-full mt-2 md:mt-0"
              >Proceed</trac-button
            >
          </div>
        </div>
      </div>
    </main>
    <div class="w-full h-screen" v-else>
      <trac-loading />
    </div>
  </div>
</template>

<script>
import { SAVE_LOCAL_DB_DATA } from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      store: null,
      stores: null,
    };
  },
  async created() {
    await this.fetchAllStores();
  },
  methods: {
    setStore(store) {
      this.store = store;
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];
      if (res.status) {
        this.stores = res.data;
        if (this.stores.length === 1) {
          this.store = this.stores[0];
          this.gotoPOSDashboard();
        }
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
    },
    gotoPOSDashboard() {
      if (this.store) {
        SAVE_LOCAL_DB_DATA("pos-sales-store", this.store);
        this.$router.push({ name: "PosDashboard" });
      }
    },
  },
};
</script>

<style></style>
